import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FsGridOptions } from 'src/app/shared/modules/fs-controls/fs-grid/fs-grid.model';
import { FsLoaderService } from 'src/app/shared/modules/fs-controls/fs-loader/fs-loader.service';
import { FsPageAction } from 'src/app/shared/modules/fs-controls/fs-page-frame/fs-page-action';
import { FsPageFrameService } from 'src/app/shared/modules/fs-controls/fs-page-frame/fs-page-frame.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { AWSResponseType } from 'src/app/shared/services/error-auth';
import { FsPageLoaderService } from 'src/app/shared/modules/fs-controls/fs-page-loader/fs-page-loader.service';
import { UrlService } from 'src/app/shared/services/url/url.service';
import { UrlParameterFor } from 'src/app/shared/services/url/url.model';
import { AlertService } from '../alert/alert.service';
import { FsToastService } from 'src/app/shared/modules/fs-popups/fs-toast/fs-toast.service';

export enum ResetPasswordPageState {
  InputEmail = 1,
  SentEmail = 2,
  NewPasswords = 3,
  Successfuly = 4,
}

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent extends FsPageAction {
  isValidPassword: boolean;

  forgotEmailFormControls = {
    email: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.maxLength(250),
    ]),
  };
  forgotEmailForm: FormGroup;

  resetPasswordFormControls = {
    code: new FormControl('', [Validators.required, Validators.maxLength(30)]),
    newPassword: new FormControl('', [
      Validators.required,
      Validators.maxLength(50),
    ]),
    againPassword: new FormControl('', [
      Validators.required,
      Validators.maxLength(50),
    ]),
  };
  resetPasswordForm: FormGroup;

  pageState = ResetPasswordPageState;
  currentPageState: ResetPasswordPageState;
  gridOptions1: FsGridOptions;
  gridOptions2: FsGridOptions;

  constructor(
    frameService: FsPageFrameService,
    private formBuilder: FormBuilder,
    public loaderService: FsLoaderService,
    private authService: AuthService,
    private urlService: UrlService,
    private toastService: FsToastService,
    public pageLoader: FsPageLoaderService,
    private alertService: AlertService
  ) {
    super(frameService);
    this.createForms();
    this.title = 'Reset Password';
    this.gridOptions1 = new FsGridOptions({
      xsm: [[12], [12], [12], [12]],
      sm: [[12], [12], [12], [12]],
      md: [[12], [12], [12], [12]],
      lg: [[12], [12], [12], [12]],
      xl: [[12], [12], [12], [12]],
      xxl: [[12], [12], [12], [12]],
    });
    this.gridOptions2 = new FsGridOptions({
      xsm: [[12], [12], [12], [12], [12], [12], [12]],
      sm: [[12], [12], [12], [12], [12], [12], [12]],
      md: [[12], [12], [12], [12], [12], [12], [12]],
      lg: [[12], [12], [12], [12], [12], [12], [12]],
      xl: [[12], [12], [12], [12], [12], [12], [12]],
      xxl: [[12], [12], [12], [12], [12], [12], [12]],
    });
    this.setCurrentPageState();
    this.isValidPassword = false;
  }

  setCurrentPageState(): void {
    if (this.urlService.urlState === UrlParameterFor.ResetPassword) {
      this.currentPageState = ResetPasswordPageState.NewPasswords;

      this.resetPasswordFormControls.code.setValue(
        this.urlService.paramData.code
      );
    } else {
      this.currentPageState = ResetPasswordPageState.InputEmail;
    }
  }

  createForms(): void {
    this.forgotEmailForm = this.formBuilder.group({
      ...this.forgotEmailFormControls,
    });
    this.resetPasswordForm = this.formBuilder.group({
      ...this.resetPasswordFormControls,
    });
  }

  async onSubmit(): Promise<void> {
    try {
      this.loaderService.loader = true;

      if (this.currentPageState === ResetPasswordPageState.InputEmail) {
        await this.onSubmitForgotEmail();
      } else if (
        this.currentPageState === ResetPasswordPageState.NewPasswords
      ) {
        await this.onSubmitNewPassword();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.loaderService.loader = false;
    }
  }

  async onSubmitForgotEmail(): Promise<void> {
    this.loaderService.loader = true;

    const result = await this.authService.forgotPassword(
      this.forgotEmailFormControls.email.value
    );

    if (result.type === AWSResponseType.Success) {
      this.currentPageState = ResetPasswordPageState.SentEmail;
    }

    this.loaderService.loader = false;
  }

  async onSubmitNewPassword(): Promise<void> {
    this.loaderService.loader = true;

    const result = await this.authService.forgotPasswordSubmit(
      this.urlService.paramData.username,
      this.resetPasswordFormControls.code.value,
      this.resetPasswordFormControls.newPassword.value
    );

    if (result?.type === AWSResponseType.Success) {
      this.frameService.resetHistory();
      this.alertService.showPasswordChanged(() => this.nextPage('/sign-in'));
      //this.pageLoader.loader = true;

      // this.router.navigateByUrl('/').then((_) => {
      //   window.localStorage.removeItem('_fs_cp_page_his');
      //   window.location.reload();
      // });
    } else {
      this.toastService.show('ERROR', 'Reset password failed');
    }

    this.loaderService.loader = false;
  }
}
