import { Component, Input } from '@angular/core';

@Component({
  selector: 'fs-grid-cell',
  templateUrl: './fs-grid-cell.component.html',
  styleUrls: ['./fs-grid-cell.component.scss'],
})
export class FsGridCellComponent {
  @Input('class') classes: string;

  constructor() {
    this.classes = '';
  }
}
