import { Injectable, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { FsPageHistoryOption } from './fs-page-frame.model';
import { NavigationExtras, Router } from '@angular/router';
import { FsAnimationService } from '../../fs-animations/fs-animation.service';
import { AnimationOptions } from '../../fs-animations/fs-animation.model';

@Injectable({
  providedIn: 'root',
})
export class FsPageFrameService {
  //Frame content from page frame component
  private _frameContent: HTMLElement;
  public get frameContent(): HTMLElement {
    return this._frameContent;
  }
  public set frameContent(v: HTMLElement) {
    this._frameContent = v;
  }

  //Header utils template
  private _startSlotTemplate: TemplateRef<any>;
  public get startSlotTemplate(): TemplateRef<any> {
    return this._startSlotTemplate;
  }
  public set startSlotTemplate(v: TemplateRef<any>) {
    this._startSlotTemplate = v;
  }

  private _endSlotTemplate: TemplateRef<any>;
  public get endSlotTemplate(): TemplateRef<any> {
    return this._endSlotTemplate;
  }
  public set endSlotTemplate(v: TemplateRef<any>) {
    this._endSlotTemplate = v;
  }

  //History section
  private _isActiveHistoryState: boolean;
  public get isActiveHistoryState(): boolean {
    return this._isActiveHistoryState;
  }
  public set isActiveHistoryState(v: boolean) {
    this._isActiveHistoryState = v;
  }

  private _pageHistory: FsPageHistoryOption[];
  public get pageHistory(): FsPageHistoryOption[] {
    return this._pageHistory;
  }

  private _historyIndex: number;
  public get historyIndex(): number {
    return this._historyIndex;
  }

  private _currentPage: FsPageHistoryOption;
  public get currentPage(): FsPageHistoryOption {
    return this._currentPage;
  }
  public set currentPage(v: FsPageHistoryOption) {
    this._currentPage = v;
  }

  private _title: string;
  public get title(): string {
    return this._title;
  }
  public set title(v: string) {
    this._title = v;
  }

  private _showBackward: boolean;
  public get showBackward(): boolean {
    return this._showBackward;
  }
  public set showBackward(v: boolean) {
    this._showBackward = v;
  }

  private pageSubscribe = new Subject<FsPageHistoryOption>();
  public pageSubscribe$ = this.pageSubscribe.asObservable();

  private saveHistorySubscribe = new Subject<FsPageHistoryOption>();
  public saveHistorySubscribe$ = this.saveHistorySubscribe.asObservable();

  constructor(private router: Router, private animation: FsAnimationService) {
    this._currentPage = new FsPageHistoryOption({});
    this.initialHistories();
    this._isActiveHistoryState = false;
    this._title = '';
    this._showBackward = true;
  }

  //frame page action
  private dismissPrevPage(): void {
    this.frameContent.querySelector('#template-page')?.remove();
  }

  private getCurrentPageBeforeNavigate(): HTMLElement {
    return this.frameContent.querySelector('*:not(router-outlet):last-child');
  }

  private getCurrentPageAfterNavigate(): HTMLElement {
    return this.frameContent.querySelector(
      ':has(#template-page) > *:last-child'
    );
  }

  //router-outlet
  //template
  //sign-in

  private renderTemplatePage(): HTMLElement {
    const current = this.getCurrentPageBeforeNavigate();
    const template = document.createElement('div');
    template.id = 'template-page';
    template.innerHTML = current.innerHTML;

    this.frameContent
      .querySelector('router-outlet')
      .insertAdjacentElement('afterend', template);

    return template;
  }

  private changeToNextPage(): void {
    const template = this.renderTemplatePage();
    template.style.zIndex = '1';

    this.animation.animate(
      new AnimationOptions({
        duration: 1000,
        timing: this.animation.easeOutQuint,
        draw: (value: number) => {
          template.style.transform = `scale(${1 - value * 0.03})`;
        },
      })
    );
  }

  private changeToPrevPage(): void {
    const template = this.renderTemplatePage();
    template.style.zIndex = '3';

    this.animation.animate(
      new AnimationOptions({
        duration: 400,
        timing: this.animation.quad,
        draw: (value: number) => {
          template.style.transform = `translateX(${value * 100}%)`;
        },
      })
    );
  }

  public goNextPage(
    currentPath: string,
    fromPath: string,
    extras?: NavigationExtras
  ): void {
    const page = new FsPageHistoryOption({
      currentPath,
      fromPath,
    });

    this.saveHistory(page);
    this._currentPage = page;

    this.changeToNextPage();

    this.router.navigateByUrl(currentPath, extras).then((_) => {
      setTimeout(async () => {
        const currentPage = this.getCurrentPageAfterNavigate();
        currentPage.style.transform = 'translateX(100%)';
        currentPage.style.display = 'block';

        await this.animation.animate(
          new AnimationOptions({
            duration: 400,
            timing: this.animation.quad,
            draw: (value: number) => {
              currentPage.style.transform = `translateX(${value * 1000}px)`;
            },
            reverse: true,
          })
        );

        this.dismissPrevPage();
      });
    });
  }

  public goPrevPage(fromPath: string, extras?: NavigationExtras): void {
    this.popHistory();
    this.changeToPrevPage();

    this.router.navigateByUrl(fromPath).then((_) => {
      setTimeout(async () => {
        const currentPage = this.getCurrentPageAfterNavigate();

        currentPage.style.transform = 'scale(0.94)';
        currentPage.style.display = 'block';

        await this.animation.animate(
          new AnimationOptions({
            duration: 300,
            timing: this.animation.easeOutQuint,
            draw: (value: number) => {
              currentPage.style.transform = `scale(${1 - value * 0.03})`;
            },
            reverse: true,
          })
        );

        this.dismissPrevPage();
      });
    });
  }

  public goBack(): void {
    let currentHistory = this._pageHistory[this._historyIndex - 1];

    if (currentHistory) {
      this.goPrevPage(currentHistory.fromPath);

      this._currentPage = currentHistory;
    }
  }
  //------------------------------------------

  private initialHistories(): void {
    let histories = window.localStorage.getItem('_fs_cp_page_his');

    if (histories?.length) {
      this._pageHistory = JSON.parse(histories) as Array<FsPageHistoryOption>;
      this._historyIndex = this._pageHistory.length;
    } else {
      this._pageHistory = new Array<FsPageHistoryOption>();
      this._historyIndex = 0;
    }
  }

  public getCurrentHistory(): FsPageHistoryOption {
    return this._pageHistory[
      this._historyIndex === 0 ? 0 : this._historyIndex - 1
    ];
  }

  public popHistory(): void {
    if (this._pageHistory?.length) {
      this._pageHistory.pop();
      this._historyIndex--;

      window.localStorage.setItem(
        '_fs_cp_page_his',
        JSON.stringify(this._pageHistory)
      );
    }
  }

  public saveHistory(history: FsPageHistoryOption): void {
    this._pageHistory.push(history);
    this._historyIndex++;

    window.localStorage.setItem(
      '_fs_cp_page_his',
      JSON.stringify(this._pageHistory)
    );

    //Clear slots
    this.startSlotTemplate = this.endSlotTemplate = null as any;

    this.saveHistorySubscribe.next(history);
  }

  public resetHistory(): void {
    this._historyIndex = 0;
    this._pageHistory = new Array<FsPageHistoryOption>();
    window.localStorage.removeItem('_fs_cp_page_his');
  }

  public existHistory(): boolean {
    return this._pageHistory?.length ? true : false;
  }
  //------------------------------------
}
