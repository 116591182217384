<div class="page-flow">
    <div class="container">
        <div *ngIf="alertService.options" class="ph-16">
            <fs-alert [title]="alertService.options.title" [message]="alertService.options.message"
                [icon]="alertService.options.icon"></fs-alert>

            <ng-container *ngFor="let handler of alertService.options.actions">
                <a *ngIf="handler.type === actionType.Link; else actionButton" class="text-center mb-16"
                    [ngClass]="{'fs-el-disabled': !handler.isActive}"
                    (click)="handler.action ? handler.action() : null">
                    {{ handler.name }}
                </a>
                <ng-template #actionButton>
                    <button type="button" fs-button [color]="handler.color" class="text-center mb-16"
                        [disabled]="!handler.isActive || loaderService.loader"
                        (click)="handler.action ? handler.action() : null">
                        {{ handler.name }}
                    </button>
                </ng-template>
            </ng-container>
        </div>
    </div>
</div>