export class PageOptions {
  pageNumber?: number;
  pageSize?: number;

  constructor(model: PageOptions) {
    this.pageNumber = model.pageNumber || 0;
    this.pageSize = model.pageSize || 1000;
  }
}

export class ListResult<T> {
  data?: Array<T>;
  pageOptions?: PageOptions;

  constructor(model: ListResult<T>) {
    this.data = model.data || (null as any);
    this.pageOptions = model.pageOptions || (null as any);
  }
}
