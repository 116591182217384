export enum PageHistoryState {
  SlideTo = 1,
  Fading = 2,
}

export class FsPageHistoryOption {
  id?: number;
  state?: PageHistoryState;
  fromPath?: string;
  currentPath?: string;
  asAlert?: boolean;

  constructor(model: FsPageHistoryOption) {
    this.id = model.id || 0;
    this.state = model.state || PageHistoryState.SlideTo;
    this.fromPath = model.fromPath || '';
    this.currentPath = model.currentPath || '';
    this.asAlert = model.asAlert || false;
  }
}
