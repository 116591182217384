export declare type JoinResponseStatus =
  | 'Default'
  | 'Success'
  | 'Already Joined'
  | 'Error';

// export class JoinResponseModel {
//   details?: string;
//   errorType?: JoinResponseStatus;
//   message?: string;
//   statusCode?: number;

//   constructor(model: JoinResponseModel) {
//     this.details = model.details || '';
//     this.errorType = model.errorType || 'Default';
//     this.message = model.message || '';
//     this.statusCode = model.statusCode || 0;
//   }
// }

export class JoinResponseModel {
  status?: string;

  constructor(model: JoinResponseModel) {
    this.status = model.status || '';
  }
}
