import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FsNavbarComponent } from './fs-navbar.component';

@NgModule({
  declarations: [FsNavbarComponent],
  imports: [CommonModule],
  exports: [FsNavbarComponent]
})
export class FsNavbarModule {}
