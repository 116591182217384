import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FsPageFrameModule } from './shared/modules/fs-controls/fs-page-frame/fs-page-frame.module';
import { SplashComponent } from './pages/splash/splash.component';
import { FsButtonModule } from './shared/modules/fs-controls/fs-button/fs-button.module';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { FsFormFieldModule } from './shared/modules/fs-controls/fs-form-field/fs-form-field.module';
import { FsInputModule } from './shared/modules/fs-controls/fs-input/fs-input.module';
import { FsLabelModule } from './shared/modules/fs-controls/fs-label/fs-label.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FsGridModule } from './shared/modules/fs-controls/fs-grid/fs-grid.module';
import { FsLoaderModule } from './shared/modules/fs-controls/fs-loader/fs-loader.module';
import { FsLoaderService } from './shared/modules/fs-controls/fs-loader/fs-loader.service';
import { FsSmallMessageDirective } from './shared/modules/fs-directives/fs-small-message.directive';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { FsAlertModule } from './shared/modules/fs-controls/fs-alert/fs-alert.module';
import { ProfileComponent } from './pages/profile/profile.component';
import { FsListSelectorModule } from './shared/modules/fs-controls/fs-list-selector/fs-list-selector.module';
import { FsImageModule } from './shared/modules/fs-controls/fs-image/fs-image.module';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { FsPasswordValidityModule } from './shared/modules/fs-controls/fs-password-validity/fs-password-validity.module';
import { CreateAccountComponent } from './pages/create-account/create-account.component';
import { FsAnimationService } from './shared/modules/fs-animations/fs-animation.service';
import { AuthService } from './shared/services/auth/auth.service';
import { HttpService } from './shared/services/http.service';
import { IndexedDBService } from './shared/services/IndexedDB.service';
import { HttpInterceptorProviders } from './shared/services/interceptor-provider';
import { FsPageLoaderModule } from './shared/modules/fs-controls/fs-page-loader/fs-page-loader.module';
import { HttpClientModule } from '@angular/common/http';
import { GlobalService } from './shared/services/global.service';
import { AuthGuard } from './shared/services/auth/auth.guard';
import { UrlService } from './shared/services/url/url.service';
import { AlertComponent } from './pages/alert/alert.component';
import { AlertService } from './pages/alert/alert.service';
import { FsToastModule } from './shared/modules/fs-popups/fs-toast/fs-toast.module';
import { HelperService } from './shared/services/helper.service';

@NgModule({
  declarations: [
    AppComponent,
    SplashComponent,
    SignInComponent,
    ResetPasswordComponent,
    ProfileComponent,
    ChangePasswordComponent,
    CreateAccountComponent,
    AlertComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    FsPageFrameModule,
    FsButtonModule,
    FsFormFieldModule,
    FsInputModule,
    FsLabelModule,
    FsGridModule,
    FsLoaderModule,
    FsAlertModule,
    FsListSelectorModule,
    FsImageModule,
    FsPasswordValidityModule,
    FsPageLoaderModule,
    FsToastModule,
    FsSmallMessageDirective,
  ],
  providers: [
    HttpInterceptorProviders,
    HttpService,
    IndexedDBService,
    GlobalService,
    FsLoaderService,
    AuthService,
    AuthGuard,
    FsAnimationService,
    UrlService,
    AlertService,
    HelperService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
