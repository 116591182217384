import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FsPageFrameComponent } from './fs-page-frame.component';
import { FsPageFrameService } from './fs-page-frame.service';
import { FsNavbarModule } from '../fs-navbar/fs-navbar.module';

@NgModule({
  declarations: [FsPageFrameComponent],
  imports: [CommonModule, FsNavbarModule],
  exports: [FsPageFrameComponent],
  providers: [FsPageFrameService],
})
export class FsPageFrameModule {}
