import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  IDisableDirective,
  IColorDirective,
  ITextTransformDirective,
} from '../../fs-directives/fs-options.directive';
import {
  FsColorPaletteSymbols,
  FsTextTransformType,
} from '../../fs-controls-shared.model';
import { TextTransformConverter } from '../../fs-controls.functions';

@Directive({
  standalone: true,
  selector: '[fs-button]',
})
export class FsButtonDirective
  implements
    OnInit,
    AfterViewInit,
    OnChanges,
    IDisableDirective,
    IColorDirective,
    ITextTransformDirective
{
  @Input('disabled') disabled: boolean;
  @Input('title') title: string;
  @Input('color') color: FsColorPaletteSymbols;
  @Input('textTransform') textTransform: FsTextTransformType;
  @Input('outline') outline: boolean;

  el: HTMLButtonElement;

  constructor(private element: ElementRef<HTMLButtonElement>) {
    this.title = '';
    this.el = element.nativeElement;
    this.textTransform = FsTextTransformType.Default;
    this.outline = false;
  }

  ngAfterViewInit(): void {
    if (this.el) {
      this.el.classList.add('fs-button');

      const text = document.createElement('span');
      text.innerText = this.el.innerText;

      this.el.innerText = '';

      const fillerDiv = document.createElement('div');
      fillerDiv.classList.add('fs-filler');

      this.el.appendChild(fillerDiv);
      this.el.appendChild(text);
    }
  }

  setTextTransform(): void {
    TextTransformConverter(this.element.nativeElement, this.textTransform);
  }

  changeDisabled(): void {
    if (this.disabled) {
      this.element.nativeElement.classList.add('fs-el-disabled');
    } else {
      this.element.nativeElement.classList.remove('fs-el-disabled');
    }

    this.el.disabled = this.disabled;
  }

  setColor(): void {
    switch (this.color) {
      case 'primary':
        this.el.classList.add('bg-primary-clr');
        break;
      case 'success':
        this.el.classList.add('bg-success-clr');
        break;
      case 'warning':
        this.el.classList.add('bg-warning-clr');
        break;
      case 'danger':
        this.el.classList.add('bg-danger-clr');
        break;
      case 'black':
        this.el.classList.add('bg-black-clr');
        break;
      case 'light-gray':
        this.el.classList.add('bg-lgray-clr');
        break;
      default:
        break;
    }
  }

  setTitle(): void {
    if (this.title?.length) {
      const ttDiv = document.createElement('div');
      ttDiv.classList.add('fs-tooltip');

      const ttSpan = document.createElement('span');
      ttSpan.innerText = this.title;
      ttDiv.appendChild(ttSpan);

      this.el.appendChild(ttDiv);
    }
  }

  setOutline(): void {
    if (this.outline) {
      if (!this.el.classList.contains('outline'))
        this.el.classList.add('outline');
    } else {
      if (this.el.classList.contains('outline'))
        this.el.classList.remove('outline');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes['disabled']) {
        this.changeDisabled();
      }

      if (changes['color']) {
        this.setColor();
      }

      if (changes['textTransform']) {
        this.setTextTransform();
      }

      if (changes['outline']) {
        this.setOutline();
      }
    }
  }

  ngOnInit(): void {
    if (this.el) {
      this.setTitle();
      this.setColor();
      this.setTextTransform();
      this.setOutline();
    }
  }
}
