export class FsGridOptions {
  xsm?: number[][];
  sm?: number[][];
  md?: number[][];
  lg?: number[][];
  xl?: number[][];
  xxl?: number[][];

  constructor(model: FsGridOptions) {
    this.xsm = model.xsm || (null as any);
    this.sm = model.sm || (null as any);
    this.md = model.md || (null as any);
    this.lg = model.lg || (null as any);
    this.xl = model.xl || (null as any);
    this.xxl = model.xxl || (null as any);
  }
}
