import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FsListSelectorComponent } from './fs-list-selector.component';

@NgModule({
  declarations: [FsListSelectorComponent],
  imports: [CommonModule],
  exports: [FsListSelectorComponent],
})
export class FsListSelectorModule {}
