import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertPageOptions } from './alert.model';
import {
  FSActionElement,
  FSActionElementType,
} from 'src/app/shared/modules/fs-controls-shared.model';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private _options: AlertPageOptions;
  public get options(): AlertPageOptions {
    return this._options;
  }
  public set options(v: AlertPageOptions) {
    this._options = new AlertPageOptions({ ...v });
  }

  constructor(private router: Router) {}

  public async show(options: AlertPageOptions): Promise<boolean> {
    this._options = options;
    return this.router.navigateByUrl('/alert');
  }

  public showConfirmEmail(
    email: string,
    reSendFunc: () => any,
    goBackFunc: () => any
  ): void {
    this.show(
      new AlertPageOptions({
        pageName: 'Verify Account',
        title: 'Please Check Your Email',
        message:
          'Please check your email for an authorization link. Click that link, then return to the Full Swing app to complete account verification.',
        subTitle: email,
        icon: 'fsi-envelope-48',
        actions: [
          new FSActionElement({
            name: 'Re-send Email',
            type: FSActionElementType.Button,
            color: 'primary',
            action: reSendFunc,
          }),
          new FSActionElement({
            name: 'Go Back',
            type: FSActionElementType.Link,
            action: goBackFunc,
          }),
        ],
      })
    );
  }

  public showSignInSuccess(signOutFunc: () => any): void {
    this.show(
      new AlertPageOptions({
        pageName: 'Sign In',
        title: 'Successfully Logged In!',
        message: 'Please return to the simulator to start playing.',
        icon: 'fsi-cir-success-48',
        actions: [
          new FSActionElement({
            name: 'Sign Out',
            type: FSActionElementType.Link,
            action: signOutFunc,
          }),
        ],
      })
    );
  }

  public showSignInSuccessWithContinue(
    continueFunc: () => any,
    signOutFunc: () => any
  ): void {
    this.show(
      new AlertPageOptions({
        pageName: 'Sign In',
        title: 'Successfully Logged In!',
        message: 'Please return to the simulator to start playing.',
        icon: 'fsi-cir-success-48',
        actions: [
          new FSActionElement({
            name: 'Continue',
            type: FSActionElementType.Button,
            color: 'primary',
            action: continueFunc,
          }),
          new FSActionElement({
            name: 'Sign Out',
            type: FSActionElementType.Link,
            action: signOutFunc,
          }),
        ],
      })
    );
  }

  public showAlreadyJoined(signOutFunc: () => any): void {
    this.show(
      new AlertPageOptions({
        pageName: 'Sign In',
        title: 'You are already logged in!',
        message: 'Please return to the simulator to start playing.',
        icon: 'fsi-cir-success-48',
        actions: [
          new FSActionElement({
            name: 'Sign Out',
            type: FSActionElementType.Link,
            action: signOutFunc,
          }),
        ],
      })
    );
  }

  public showAccountVerified(continueFunc: () => any): void {
    this.show(
      new AlertPageOptions({
        pageName: 'Account Verified',
        title: 'Welcome to Full Swing!',
        message: 'Please sign in with your new account to continue.',
        icon: 'fsi-cir-success-48',
        actions: [
          new FSActionElement({
            name: 'Continue',
            type: FSActionElementType.Button,
            color: 'primary',
            action: continueFunc,
          }),
        ],
      })
    );
  }

  public showPasswordChanged(signInFunc: () => any): void {
    this.show(
      new AlertPageOptions({
        pageName: 'Reset Password',
        title: 'Password Changed!',
        message: 'Tap below to sign in with your new password.',
        icon: 'fsi-cir-success-48',
        actions: [
          new FSActionElement({
            name: 'Sign In',
            type: FSActionElementType.Button,
            color: 'primary',
            action: signInFunc,
          }),
        ],
      })
    );
  }

  public showReadyToPlay(signOutFunc: () => any): void {
    this.show(
      new AlertPageOptions({
        pageName: 'Account Setup',
        title: 'You’re Ready to Play!',
        message:
          'Your account has been created and you have been successfully logged in. Please return to the simulator to start playing!',
        icon: 'fsi-cir-success-48',
        actions: [
          new FSActionElement({
            name: 'Sign Out',
            type: FSActionElementType.Link,
            action: signOutFunc,
          }),
        ],
      })
    );
  }

  public showAccountCreated(signOutFunc: () => any): void {
    this.show(
      new AlertPageOptions({
        pageName: 'Account Setup',
        title: 'Account Created!',
        message:
          'Your account has been created. Scan the QR Code on a Full Swing Simulator to start playing!',
        icon: 'fsi-cir-success-48',
        actions: [
          new FSActionElement({
            name: 'Sign Out',
            type: FSActionElementType.Link,
            action: signOutFunc,
          }),
        ],
      })
    );
  }

  public showAccountCreatedWithContinue(
    continueFunc: () => any,
    signOutFunc: () => any
  ): void {
    this.show(
      new AlertPageOptions({
        pageName: 'Account Setup',
        title: 'Account Created!',
        message:
          'Your account has been created. Scan the QR Code on a Full Swing Simulator to start playing!',
        icon: 'fsi-cir-success-48',
        actions: [
          new FSActionElement({
            name: 'Continue',
            type: FSActionElementType.Button,
            color: 'primary',
            action: continueFunc,
          }),
          new FSActionElement({
            name: 'Sign Out',
            type: FSActionElementType.Link,
            action: signOutFunc,
          }),
        ],
      })
    );
  }
}
