import { Gender, GenderSymbols } from './enums/gender.enum';
import { Handedness, HandednessSymbols } from './enums/handedness.enum';
import { SelectItem } from './modules/fs-controls-shared.model';

export const HandicapRange = {
  min: 0,
  max: 54,
  default: null,
};

export const HandednessData: Array<SelectItem> = [
  new SelectItem({
    id: 1,
    name: 'Right',
    value: Handedness.Right,
    isActive: true,
  }),
  new SelectItem({
    id: 2,
    name: 'Left',
    value: Handedness.Left,
    isActive: true,
  }),
];

export const getHandednessAsString = (value: HandednessSymbols): Handedness => {
  switch (value) {
    case 'Right':
      return Handedness.Right;
    case 'Left':
      return Handedness.Left;
    default:
      return Handedness.None;
  }
};

export const getHandednessAsEnum = (value: Handedness): HandednessSymbols => {
  switch (value) {
    case Handedness.Right:
      return 'Right';
    case Handedness.Left:
      return 'Left';
    default:
      return 'None';
  }
};

export const GenderData: Array<SelectItem> = [
  new SelectItem({
    id: 1,
    name: 'Male',
    value: Gender.Male,
    isActive: true,
  }),
  new SelectItem({
    id: 2,
    name: 'Female',
    value: Gender.Female,
    isActive: true,
  }),
];

export const getGenderAsString = (value: GenderSymbols): Gender => {
  switch (value) {
    case 'Male':
      return Gender.Male;
    case 'Female':
      return Gender.Female;
    default:
      return Gender.None;
  }
};

export const getGenderAsEnum = (value: Gender): GenderSymbols => {
  switch (value) {
    case Gender.Female:
      return 'Female';
    case Gender.Male:
      return 'Male';
    default:
      return 'None';
  }
};
