import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'fs-password-validity',
  templateUrl: './fs-password-validity.component.html',
  styleUrls: ['./fs-password-validity.component.scss'],
})
export class FsPasswordValidityComponent implements AfterViewInit, OnChanges {
  @Input('confirmPassword') confirmPassword: AbstractControl;
  @Input('newPassword') newPassword: AbstractControl;

  @Output('validationResult') validationResult = new EventEmitter<boolean>();

  isPasswordLawerCase: boolean;
  isPasswordUpperCase: boolean;
  isPasswordHasNumber: boolean;
  isPasswordHasSpecialCharacter: boolean;
  isPasswordUpTo8Characters: boolean;
  isMatchPasswords: boolean;

  constructor() {
    this.isPasswordHasNumber =
      this.isPasswordHasSpecialCharacter =
      this.isPasswordLawerCase =
      this.isPasswordUpperCase =
      this.isPasswordUpTo8Characters =
      this.isMatchPasswords =
        false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.newPassword.value?.length && this.confirmPassword.value?.length) {
      this.checkValidations();
      this.isValidPasswords();
    }
  }

  ngAfterViewInit(): void {
    this.newPassword.valueChanges.subscribe((value) => {
      this.checkValidations();
      this.validationResult.emit(this.isValidPassword());
    });

    this.confirmPassword.valueChanges.subscribe((value) => {
      this.isValidPasswords();
    });
  }

  checkValidations(): void {
    this.isPasswordLawerCase = /[a-z]/.test(this.newPassword.value);
    this.isPasswordUpperCase = /[A-Z]/.test(this.newPassword.value);
    this.isPasswordHasNumber = /[0-9]/.test(this.newPassword.value);
    this.isPasswordHasSpecialCharacter =
      /[-+=!$%^&*()_|~`{}\[\]:\/;<>?,.@#'"]/.test(this.newPassword.value);
    this.isPasswordUpTo8Characters = this.newPassword.value.length > 7;
    this.isMatchPasswords =
      this.newPassword.value === this.confirmPassword.value;
  }

  isValidPassword(): boolean {
    return (
      this.isMatchPasswords &&
      this.isPasswordHasNumber &&
      this.isPasswordHasSpecialCharacter &&
      this.isPasswordLawerCase &&
      this.isPasswordUpperCase &&
      this.isPasswordUpTo8Characters
    );
  }

  isValidPasswords(): void {
    this.isMatchPasswords =
      this.confirmPassword.value === this.newPassword.value;
    this.validationResult.emit(this.isValidPassword());
  }
}
