import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FsPageLoaderComponent } from './fs-page-loader.component';
import { FsSpinnerModule } from '../fs-spinner/fs-spinner.module';
import { FsPageLoaderService } from './fs-page-loader.service';

@NgModule({
  declarations: [FsPageLoaderComponent],
  imports: [CommonModule, FsSpinnerModule],
  exports: [FsPageLoaderComponent],
  providers: [FsPageLoaderService],
})
export class FsPageLoaderModule {}
