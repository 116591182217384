<div #fsFormField class="fs-form-field" [class]="classes"
    [ngClass]="{'field-events': !readOnly, 'invalid-field': invalid}">
    <div *ngIf="readOnly" class="readonly-field"></div>

    <div class="fs-field-wrapper" [style]="{'padding-inline-end': !hideActions ? '0px' : '16px'}">
        <ng-content></ng-content>
    </div>

    <div *ngIf="!hideActions && innerText?.length && !inputEl?.readOnly" class="action-field">
        <button *ngIf="inputType !== 'password' && !hideClearAction" type="button" class="clear-button"
            (click)="onClearText()"></button>
        <button *ngIf="inputType === 'password'" type="button" class="change-visibility-pass"
            [ngStyle]="{'background-image': inputEl.type === 'password' ? 'url(./assets/fs-controls-assets/icons/visibility-on-24.svg)' : 'url(./assets/fs-controls-assets/icons/visibility-off-24.svg)'}"
            (click)="onVisibilityPassword()"></button>
    </div>

    <div *ngIf="inputType === 'date'" class="action-field">
        <button type="button" class="fsi-calendar" (click)="onClickDate()"></button>
    </div>

    <ng-content select="[fs-form-field-actions]"></ng-content>
    <a *ngIf="activeClickable" class="mask-link"></a>
</div>