export declare type ApplicationId =
  | 'FS_LAUNCHER'
  | 'FS_COMPETE'
  | 'FS_AUTH'
  | 'OTHER';

export enum ApplicationIdEnum {
  FS_LAUNCHER = 'FS_LAUNCHER',
  FS_COMPETE = 'FS_COMPETE',
  FS_AUTH = 'FS_AUTH',
  OTHER = 'OTHER',
}
