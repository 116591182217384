import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[fs-input]',
})
export class FsInputDirective implements OnChanges {
  @Input('readonly') readonly: boolean;

  el: HTMLInputElement;

  constructor(private element: ElementRef) {
    this.el = element.nativeElement;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes['readonly']) {
        if (changes['readonly'].currentValue) {
          this.el.parentElement.parentElement.classList.add(
            'fs-input-field-readonly'
          );
        } else {
          this.el.parentElement.parentElement.classList.remove(
            'fs-input-field-readonly'
          );
        }

        this.el.readOnly = changes['readonly'].currentValue;
      }
    }
  }
}
