import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FsFormFieldComponent } from './fs-form-field.component';

@NgModule({
  declarations: [FsFormFieldComponent],
  imports: [CommonModule],
  exports: [FsFormFieldComponent],
})
export class FsFormFieldModule {}
