import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'fs-navbar',
  templateUrl: './fs-navbar.component.html',
  styleUrls: ['./fs-navbar.component.scss'],
})
export class FsNavbarComponent implements OnInit {
  @ViewChild('navbarRef') navbarRef: ElementRef<HTMLElement>;

  @Input('showLogo') showLogo: boolean;
  @Input('title') title: string;
  @Input('showBackward') showBackward: boolean;

  @Output('onBackward') onBackward = new EventEmitter<void>();

  constructor() {
    this.title = '';
    this.showLogo = true;
    this.showBackward = false;
  }

  ngOnInit(): void {
    fromEvent(document.body, 'scroll').subscribe((result: Event) => {
      if ((<HTMLElement>result.target).scrollTop > 300) {
        this.navbarRef.nativeElement.classList.add('sm');
      } else {
        this.navbarRef.nativeElement.classList.remove('sm');
      }
    });
  }
}
