import { Component, OnDestroy, OnInit } from '@angular/core';
import { FsPageAction } from 'src/app/shared/modules/fs-controls/fs-page-frame/fs-page-action';
import { FsPageFrameService } from 'src/app/shared/modules/fs-controls/fs-page-frame/fs-page-frame.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { GlobalService } from 'src/app/shared/services/global.service';

@Component({
  selector: 'splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss'],
})
export class SplashComponent extends FsPageAction implements OnInit, OnDestroy {
  accountButtonTitle: string;
  viewProfile: boolean;

  constructor(
    frameService: FsPageFrameService,
    private authService: AuthService,
    private globalService: GlobalService
  ) {
    super(frameService);
    this.title = '';
    this.viewProfile = false;
    this.accountButtonTitle = 'Create a Full Swing account';
  }

  ngOnDestroy(): void {
    this.frameService.showBackward = true;
  }

  ngOnInit(): void {
    this.frameService.showBackward = false;

    if (this.authService.isAuthenticated) {
      if (!this.globalService.checkAccountIsCompleted()) {
        this.accountButtonTitle = 'Complete Full Swing account';
      } else {
        // this.accountButtonTitle = 'View Profile';
        // this.viewProfile = true;
      }
    }
  }
}
