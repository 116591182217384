import { SelectItem } from '../../fs-controls-shared.model';

export class SelectorExpand {
  expand: boolean;
  value: SelectItem;

  constructor(model: SelectorExpand) {
    this.expand = model.expand;
    this.value = model.value;
  }
}
