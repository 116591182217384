import { AfterViewInit, Component } from '@angular/core';
import { FsPageAction } from 'src/app/shared/modules/fs-controls/fs-page-frame/fs-page-action';
import { FsPageFrameService } from 'src/app/shared/modules/fs-controls/fs-page-frame/fs-page-frame.service';
import { AlertService } from './alert.service';
import { FSActionElementType } from 'src/app/shared/modules/fs-controls-shared.model';
import { Router } from '@angular/router';
import { FsLoaderService } from 'src/app/shared/modules/fs-controls/fs-loader/fs-loader.service';

@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent extends FsPageAction implements AfterViewInit {
  readonly actionType = FSActionElementType;

  constructor(
    frameService: FsPageFrameService,
    public alertService: AlertService,
    private router: Router,
    public loaderService: FsLoaderService
  ) {
    super(frameService);
    this.title = this.alertService.options?.pageName;
  }

  ngAfterViewInit(): void {
    if (!this.alertService.options) {
      this.router.navigateByUrl('/');
    }
  }
}
