import { FSActionElement } from 'src/app/shared/modules/fs-controls-shared.model';

export class AlertPageOptions {
  pageName?: string;
  title?: string;
  subTitle?: string;
  message?: string;
  icon?: string;
  actions?: FSActionElement<any>[];

  constructor(model: AlertPageOptions) {
    this.pageName = model.pageName || '';
    this.title = model.title || '';
    this.subTitle = model.subTitle || '';
    this.message = model.message || '';
    this.icon = model.icon || '';
    this.actions = model.actions || new Array<FSActionElement<any>>();
  }
}
