import { FsTextTransformType } from './fs-controls-shared.model';

export const TextTransformConverter = (
  el: HTMLElement,
  type: FsTextTransformType
): void => {
  switch (type) {
    case FsTextTransformType.Capitalize:
      el.classList.add('cap-txt-trans');
      break;
    case FsTextTransformType.Lowercase:
      el.classList.add('low-txt-trans');
      break;
    case FsTextTransformType.Uppercase:
      el.classList.add('upr-txt-trans');
      break;
    case FsTextTransformType.Inherit:
      el.classList.add('inh-txt-trans');
      break;
    case FsTextTransformType.Default:
      el.classList.add('def-txt-trans');
      break;
    default:
      //TODO
      break;
  }
};
