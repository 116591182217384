<ng-container *ngIf="!pageLoader.loader">
    <ng-container *ngIf="applicationReady">

        <main>
            <fs-page-frame>

                <router-outlet></router-outlet>

            </fs-page-frame>
        </main>

        <fs-loader></fs-loader>

    </ng-container>
</ng-container>

<fs-page-loader></fs-page-loader>
<fs-toast></fs-toast>