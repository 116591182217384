import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Gender } from 'src/app/shared/enums/gender.enum';
import { Handedness } from 'src/app/shared/enums/handedness.enum';
import { UpdatePlayerRequest } from 'src/app/shared/models/player.model';
import { SelectItem } from 'src/app/shared/modules/fs-controls-shared.model';
import { FsGridOptions } from 'src/app/shared/modules/fs-controls/fs-grid/fs-grid.model';
import { FsLoaderService } from 'src/app/shared/modules/fs-controls/fs-loader/fs-loader.service';
import { FsPageAction } from 'src/app/shared/modules/fs-controls/fs-page-frame/fs-page-action';
import { FsPageFrameService } from 'src/app/shared/modules/fs-controls/fs-page-frame/fs-page-frame.service';
import { FsToastService } from 'src/app/shared/modules/fs-popups/fs-toast/fs-toast.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { GlobalService } from 'src/app/shared/services/global.service';
import {
  GenderData,
  HandednessData,
  HandicapRange,
} from 'src/app/shared/static-data';

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent extends FsPageAction implements OnInit {
  gridOptions: FsGridOptions;
  handicapLimits = HandicapRange;

  genderSelectors: SelectItem[];
  handednessSelector: SelectItem[];

  profileFormControls = {
    email: new FormControl('', [
      Validators.email,
      Validators.maxLength(250),
      Validators.required,
    ]),
    firstName: new FormControl('', [
      Validators.required,
      Validators.maxLength(250),
    ]),
    lastName: new FormControl('', [
      Validators.required,
      Validators.maxLength(250),
    ]),
    handedness: new FormControl(Handedness.None, []),
    gender: new FormControl(Gender.None, []),
    handicap: new FormControl(HandicapRange.default, [
      Validators.required,
      Validators.min(HandicapRange.min),
      Validators.max(HandicapRange.max),
      Validators.maxLength(2),
    ]),
  };
  profileForm: FormGroup;

  constructor(
    frameService: FsPageFrameService,
    private builder: FormBuilder,
    public loaderService: FsLoaderService,
    public globalService: GlobalService,
    private authService: AuthService,
    private toastService: FsToastService
  ) {
    super(frameService);
    this.createForms();
    this.title = 'Profile';
    this.gridOptions = new FsGridOptions({
      xsm: [[12], [12], [6, 6], [4, 4, 4], [12], [12]],
      sm: [[12], [12], [6, 6], [4, 4, 4], [12], [12]],
      md: [[12], [12], [6, 6], [4, 4, 4], [12], [12]],
      lg: [[12], [12], [6, 6], [4, 4, 4], [12], [12]],
      xl: [[12], [12], [6, 6], [4, 4, 4], [12], [12]],
      xxl: [[12], [12], [6, 6], [4, 4, 4], [12], [12]],
    });
    this.handednessSelector = HandednessData;
    this.genderSelectors = GenderData;
  }

  ngOnInit(): void {
    this.profileForm.patchValue({ ...this.globalService.player });
  }

  createForms(): void {
    this.profileForm = this.builder.group({ ...this.profileFormControls });
  }

  async onSubmit(): Promise<void> {
    this.loaderService.loader = true;

    const updateRequest = new UpdatePlayerRequest({
      ...this.globalService.player,
      ...this.profileForm.getRawValue(),
      email: this.globalService.player.email,
    });

    const result = await this.authService.updateCompetePlayer(updateRequest);

    if (result) {
      this.globalService.player.handedness = updateRequest.handedness;
      this.globalService.player.gender = updateRequest.gender;
      this.globalService.player.handicap = updateRequest.handicap;
      this.globalService.player.firstName = updateRequest.firstName;
      this.globalService.player.lastName = updateRequest.lastName;
    } else {
      this.toastService.show('ERROR', 'Update failed');
    }

    this.loaderService.loader = false;
  }
}
