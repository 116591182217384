import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FsOverlayOptions, SelectItem } from '../../fs-controls-shared.model';
import { SelectorExpand } from './fs-list-selector.model';
import { FsControlService } from '../../fs-controls.service';
import { convertToFlatArray } from 'src/app/shared/helper-functions';

@Component({
  selector: 'fs-list-selector',
  templateUrl: './fs-list-selector.component.html',
  styleUrls: ['./fs-list-selector.component.scss'],
})
export class FsListSelectorComponent implements OnInit, OnChanges {
  @ViewChild('fsListSelector') fsListSelector: ElementRef;
  @ViewChild('fsListItemsRef') fsListItemsRef: ElementRef;

  @Input() value: any;
  @Output() valueChange = new EventEmitter<any>();

  @Input('hideLabel') hideLabel: boolean;
  @Input('hideList') hideList: boolean;
  @Input('width') width: number;
  @Input('placeholder') placeholder: string;
  @Input('classes') classes: string;
  @Input('hasNedtedValues') hasNedtedValues: boolean;
  @Input('options') options: SelectItem[];

  @Output('onChangeItem') onChangeItem = new EventEmitter<SelectItem>();
  @Output('onExpandList') onExpandList = new EventEmitter<SelectorExpand>();

  currentValue: SelectItem;
  isExpanded: boolean;

  constructor(public controlService: FsControlService) {
    this.options = new Array<SelectItem>();
    this.currentValue = new SelectItem({});
    this.isExpanded =
      this.hideList =
      this.hasNedtedValues =
      this.hideLabel =
        false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      this.loadValue();
    }
  }

  ngOnInit(): void {
    if (this.value) this.loadValue();

    this.controlService.destroyOverlay$.subscribe((_) => {
      setTimeout(() => {
        this.isExpanded = false;

        this.onExpandList.emit(
          new SelectorExpand({
            expand: this.controlService.activeOverlay,
            value: this.currentValue,
          })
        );
      }, 10);
    });
  }

  onClickItem(item: SelectItem): void {
    this.currentValue = item;
    this.value = item.value;
    this.valueChange.emit(item.value);
    this.onChangeItem.emit(item);
    this.onClickExpandList();
  }

  loadValue(): void {
    this.options.every((option) => {
      if (option.value === this.value) {
        this.currentValue = option;
        return false;
      } else {
        if (option.childs?.length) {
          let value = option.childs.find((f) => f.value === this.value);

          if (value) {
            this.currentValue = value;
            return false;
          }
        }
      }

      return true;
    });
  }

  onClickExpandList(): void {
    if (!this.options || !this.options.length) return;

    if (!this.controlService.activeOverlay) {
      this.isExpanded = true;

      setTimeout(() => {
        this.controlService.renderOverlayContent(
          new FsOverlayOptions({
            contentRef: this.fsListItemsRef.nativeElement,
            parentEl: this.fsListSelector.nativeElement,
            width: this.width,
            contentItemsCount: this.countListItems(),
            classes: this.classes,
          })
        );

        this.scrollToSelectedItem();
        this.onExpandList.emit(
          new SelectorExpand({
            expand: this.controlService.activeOverlay,
            value: this.currentValue,
          })
        );
      }, 10);
    }
  }

  countListItems(): number {
    if (this.hasNedtedValues) {
      return convertToFlatArray<any>(this.options.map((m) => m.childs)).length;
    } else {
      return this.options.length;
    }
  }

  scrollToSelectedItem(): void {
    if (this.value) {
      let selectedItem = (
        this.fsListItemsRef.nativeElement as HTMLElement
      ).querySelector(`#item-${this.value}`);
      selectedItem?.scrollIntoView({
        block: 'center',
      });
    }
  }
}
